const {
  PROD_CMS_URI,
  STAGE_CMS_URI,
  PROD_API_URI,
  STAGE_API_URI,
  DEV_CMS_URI
} = process.env;

export function CMS_ENDPOINT() {
  // switch (true) {
  //   case IS_PROD(): return PROD_CMS_URI;
  //   case IS_STAGE(): return STAGE_CMS_URI;
  //   case IS_DEV(): return process.env.NODE_ENV === 'production' ? PROD_CMS_URI : DEV_CMS_URI;
  //   default: return PROD_CMS_URI;
  // }
  return DEV_CMS_URI;
}

export function API_ENDPOINT() {
  switch (true) {
    case IS_PROD(): return PROD_API_URI;
    case IS_STAGE(): return STAGE_API_URI;
    case IS_DEV(): return process.env.NODE_ENV === 'production' ? PROD_API_URI : STAGE_API_URI;
    default: return PROD_API_URI;
  }
}

export function API_WSS_ENDPOINT() {
  switch (true) {
    case IS_PROD(): return PROD_API_URI;
    case IS_STAGE(): return STAGE_API_URI;
    case IS_DEV(): return process.env.NODE_ENV === 'production' ? PROD_API_URI : STAGE_API_URI;
    default: return PROD_API_URI;
  }
}

export function IS_PROD() {
  return window.location.hostname === 'koodoo.global';
}

export function IS_STAGE() {
  return window.location.hostname === 'staging.koodoo.global';
}

export function IS_DEV() {
  return Boolean(
    window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
  );
}
