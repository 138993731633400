import { Cookies } from 'react-cookie';

const cookie = new Cookies();

const setCookie = (hour, cookieName, data) => {
  const d = new Date();
  d.setTime(d.getTime() + (hour * 60 * 60 * 1000));
  const thisData = JSON.stringify(data);
  cookie.set(cookieName, JSON.stringify(thisData), {
    sameSite: 'strict',
    expires: d
  });
};

const getCookie = (cookieName, rType = {}) => {
  if (cookie.get(cookieName)) return JSON.parse(cookie.get(cookieName));
  return rType;
};

export { setCookie, getCookie };
