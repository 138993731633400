import { createReduxModule } from 'hooks-for-redux';
import { getItem, removeItem, setItem } from '../Services/localStorage';
import { GAEvent } from '../Analytics/Events';
import { api } from '../Api';
import { getCookie } from '../Services/cookieStore';

const storeUser = getItem('auth_user');

const initialState = {
  loggedIn: (storeUser && storeUser.loggedIn) || false,
  signupOpen: false,
  newsletterOpen: false,
  signupErrored: false,
  role: (storeUser && storeUser.role) || 'GUEST',
  token: (storeUser && storeUser.token) || '',
  userId: (storeUser && storeUser.userId) || '',
  displayName: (storeUser && storeUser.displayName) || '',
  email: (storeUser && storeUser.email) || '',
  cell: (storeUser && storeUser.cell) || '',
  kycPostMetaData: (storeUser && storeUser.kycPostMetaData) || [],
  kycPreMetaData: (storeUser && storeUser.kycPreMetaData) || [],
  subscribedToNewsletter: (storeUser && storeUser.subscribedToNewsletter) || false,
  verified: (storeUser && storeUser.verified) || false,
  snackbar: {
    open: false,
    message: 'Snack on me',
    severity: 'info',
    vertical: 'top',
    horizontal: 'center'
  },
  alerts: [],
  darkMode: getCookie('UserDarkMode', null) || false,
  socialLinks: [
    {
      title: 'facebook',
      link: ''
    },
    {
      title: 'linkedin',
      link: ''
    },
    {
      title: 'twitter',
      link: ''
    }
  ]
};

export const [useUserState, {
  setUserState, setSignupOpen, setNewsletterOpen, resetUserState, setSnackbar, setAlerts, setDarkMode
}, userStateStore] = createReduxModule(
  'userState',
  initialState,
  {
    setUserState: (state, userData) => {
      const loggedIn = 'token' in userData ? userData.token.length > 0 : state.token.length > 0;
      setItem('auth_user', { ...state, ...userData, loggedIn });
      return { ...state, ...userData, loggedIn };
    },
    setSignupOpen: (state, signupOpen) => {
      GAEvent({
        category: 'CLICK_EVENT',
        action: `User ${signupOpen ? 'opened' : 'closed'} signup modal`,
        label: `Path: ${window.location.pathname}`
      });
      return ({ ...state, signupOpen });
    },
    setNewsletterOpen: (state, newsletterOpen) => {
      GAEvent({
        category: 'CLICK_EVENT',
        action: `User ${newsletterOpen ? 'opened' : 'closed'} newsletter signup modal`,
        label: `Path: ${window.location.pathname}`
      });
      return ({ ...state, newsletterOpen });
    },
    resetUserState: () => {
      removeItem('auth_user');
      return initialState;
    },
    setSnackbar: (state, snackbar) => ({ ...state, snackbar }),
    setAlerts: (state, alerts) => ({ ...state, alerts }),
    setDarkMode: (state, darkMode) => ({ ...state, darkMode })
  }
);
