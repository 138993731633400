import ReactGA from 'react-ga';
import { IS_PROD } from '../Services/domainController';

const EVENT_CATEGORIES = [
  'USER',
  'USER_ACCOUNT',
  'REDIRECT',
  'CLICK_EVENT',
  'VIDEO_EVENT',
  'USER_DOING_SILLY_THINGS',
  'ERROR_EVENT',
  'PAYMENT_EVENT'
];

export function GAEvent({ category, action, label }) {
  if (EVENT_CATEGORIES.includes(category) && IS_PROD()) {
    ReactGA.event({
      category,
      action,
      label
    });
  }
}

export function ExecuteVitals(data) {
  if (IS_PROD()) {
    ReactGA.plugin.execute('web_vitals', {
      vitals_metric: data
    });
  }
}
