import '@capacitor/core';
import React, { useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'hooks-for-redux';
import { ToastProvider } from 'react-toast-notifications';
import reportWebVitals from './reportWebVitals';
import { registerSW } from './registerServiceWorker';

import { RocketLoader } from './Views/Shared/Loaders/rocketLoader';
import ThemeProvider from './Themes/themeProvider';

import 'bootstrap/dist/js/bootstrap.bundle';
import './Assets/Styles/_main.scss';
import { getCookie, setCookie } from './Services/cookieStore';
import { ExecuteVitals, GAEvent } from './Analytics/Events';

const Views = React.lazy(() => Promise.all([
  import('./Views'),
  new Promise(resolve => setTimeout(resolve, 1000))
]).then(([moduleExports]) => moduleExports));

const { NODE_ENV } = process.env;

const rootElement = document.getElementById('root');

if (
  typeof getCookie('newUser') === 'undefined' || getCookie('newUser') === null
) {
  GAEvent({
    category: 'USER',
    action: 'New Site Visitor',
    label: 'Path: /'
  });
  setCookie(9999, 'newUser', true);
} else {
  GAEvent({
    category: 'USER',
    action: 'Returning Site Visitor',
    label: 'Path: /'
  });
}

window.loadPromise = new Promise((resolve) => {
  window.addEventListener('load', () => {
    window.scrollTo(0, 0);
    resolve();
  });
});

const App = () => (
  <Provider>
    <ThemeProvider>
      <Suspense fallback={<RocketLoader />}>
        <ToastProvider>
          <Views />
        </ToastProvider>
      </Suspense>
    </ThemeProvider>
  </Provider>
);

const renderApp = () => {
  ReactDOM.render(<App />, rootElement);
};

renderApp();

reportWebVitals(ExecuteVitals);
registerSW();
