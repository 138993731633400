import axios from 'axios';

import { getItem } from '../Services/localStorage';
import { API_ENDPOINT } from '../Services/domainController';

const apiEndpoint = API_ENDPOINT();
const version = 'v1';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.withCredentials = true;
if (getItem('auth_user')?.token) {
  axios.defaults.headers.common.Authorization = `Bearer ${getItem('auth_user').token}`;
}

export const api = {
  moneyPoesApiKak: {
    getShit: {
      poesBIPSValue: () => axios.get(`${apiEndpoint}/${version}/payments-bips-price`)
    }
  },
  auth: {
    get: {
      logout: () => axios.get(`${apiEndpoint}/${version}/auth/logout`),
      me: () => axios.get(`${apiEndpoint}/${version}/auth/me`),
      token: () => axios.get(`${apiEndpoint}/${version}/auth/token`)
    },
    post: {
      login: (loginData = { email: '', password: '' }) => axios.post(`${apiEndpoint}/${version}/auth/login`, loginData),
      register: (regData = { email: '', password: '', name: '' }) => axios.post(`${apiEndpoint}/${version}/auth/register`, regData),
      forgot: (forgotData = { email: '' }) => axios.post(`${apiEndpoint}/${version}/auth/password-reset`, forgotData),
      verify: (verifyData = { userId: '' }) => axios.post(`${apiEndpoint}/${version}/auth/verify`, verifyData),
      emailVerification: () => axios.post(`${apiEndpoint}/${version}/auth/request-verification`),
      reset: (resetData = { token: '', password: '' }) => axios.post(`${apiEndpoint}/${version}/auth/reset-password`, resetData)
    }
  },
  availabilityZone: {
    get: {
      all: () => axios.get(`${apiEndpoint}/${version}/availability-zones`),
      zone: () => {},
      zoneById: () => {}
    },
    post: {
      zone: () => {}
    },
    put: {
      zoneById: () => {}
    }
  },
  kyc: {
    get: {
      userById: (userId) => axios.get(`${apiEndpoint}/${version}/kyc/${userId}`),
      statusByUserById: (userId) => axios.get(`${apiEndpoint}/${version}/kyc/${userId}?verify=true&provider=MONEYBRAIN`)
    },
    post: {
      create: (kycData = { provider: '', userId: '' }) => axios.post(`${apiEndpoint}/${version}/kyc`, kycData)
    }
  },
  userLeads: {
    get: () => axios.get(`${apiEndpoint}/${version}/userleads`),
    post: (userleadData = {
      name: '',
      email: '',
      password: '',
      subscribedToNewsletter: true,
      interests: [''],
      cell: ''
    }) => axios.post(`${apiEndpoint}/${version}/userleads`, userleadData)
  },
  userService: {
    get: {
      me: () => axios.get(`${apiEndpoint}/${version}/me`),
      userById: (userId) => axios.get(`${apiEndpoint}/${version}/users/${userId}`),
      pledges: () => axios.get(`${apiEndpoint}/${version}/pledges?limit=100`),
      payments: () => axios.get(`${apiEndpoint}/${version}/payments/`)
    },
    post: {
      refundPayment: (paymentId) => axios.post(`${apiEndpoint}/${version}/payments/refund/${paymentId}`)
    },
    update: {
      userById: (userId, updateData = {
        name: 'string',
        email: 'string',
        cell: 'string'
      }) => axios.put(`${apiEndpoint}/${version}/users/${userId}`, updateData),
      userPassword: (userId, updatedPassword = {
        password: ''
      }) => axios.put(`${apiEndpoint}/${version}/users/${userId}`, updatedPassword),
      userCell: (userId, updatedCell = {
        cell: ''
      }) => axios.put(`${apiEndpoint}/${version}/users/${userId}`, updatedCell),
      userLinks: (userId, updateLinks = []) => axios.put(`${apiEndpoint}/${version}/users/${userId}`, updateLinks)
    }
  },
  campaignService: {
    get: {
      campaigns: (limit) => axios.get(`${apiEndpoint}/${version}/campaigns?limit=${limit}`),
      campaignById: (campaignId) => axios.get(`${apiEndpoint}/${version}/campaigns/${campaignId}`),
      campaignsByUser: (userId) => axios.get(`${apiEndpoint}/${version}/campaigns?owner=${userId}`),
      campaignsByOrg: (orgId) => axios.get(`${apiEndpoint}/${version}/campaigns?organisation=${orgId}`),
      campaignsByStatus: (status = 'ACTIVE', limit = 200) => axios.get(`${apiEndpoint}/${version}/campaigns?status=${status}&limit=${limit}`)
    },
    post: {
      createCampaign: (data) => axios.post(`${apiEndpoint}/${version}/campaigns`, data)
    }
  },
  organisationService: {
    get: {
      organisationById: (orgId) => axios.get(`${apiEndpoint}/${version}/organisations/${orgId}`),
      organisations: () => axios.get(`${apiEndpoint}/${version}/organisations`),
      organisationsByName: (name) => axios.get(`${apiEndpoint}/${version}/organisations/?name=${name}`)
    },
    post: {
      createOrganisation: (data) => axios.post(`${apiEndpoint}/${version}/organisations`, data)
    }
  },
  upload: {
    post: {
      uploadImage: (data, config) => axios.post(`${apiEndpoint}/${version}/images/upload`, data, {
        headers: { 'content-type': 'multipart/form-data' }
      }),
      orgDocs: (id, data, onUploadProgress = () => {}) => axios.post(`${apiEndpoint}/${version}/organisations/${id}/documents`, data, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress
      }),
      campaignDocs: (id, data, onUploadProgress = () => {}) => axios.post(`${apiEndpoint}/${version}/campaigns/${id}/documents`, data, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress
      }),
      userDocs: (id, data, onUploadProgress = () => {}) => axios.post(`${apiEndpoint}/${version}/users/${id}/documents`, data, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress
      })
    },
    delete: {
      deleteUserDoc: (uId, docId) => axios.delete(`${apiEndpoint}/${version}/users/${uId}/documents/${docId}`)
    }
  },
  pledge: {
    post: {
      pledges: (data) => axios.post(`${apiEndpoint}/${version}/pledges`, data)
    }
  },
  payments: {
    put: {
      payments: (paymentId, gateway) => axios.put(`${apiEndpoint}/${version}/payments/${paymentId}`, gateway)
    },
    get: {
      verifyPayment: (id) => axios.get(`${apiEndpoint}/${version}/payments-verify/${id}`)
    }
  },
  notifications: {
    post: {
      updateEmail: (updateData = {
        type: 'mail',
        mailNotifications: {}
      }) => axios.post(`${apiEndpoint}/${version}/notifications`, updateData)
    }
  }
};

function fetchResource(url) {
  return fetch(url).then(response => response.json());
}
