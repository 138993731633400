import React, { useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useUserState, setDarkMode } from '../Reducers/userState';
import { getCookie } from '../Services/cookieStore';
import lightTheme from './light';
import darkTheme from './dark';

const ThemeProvider = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const userChoice = getCookie('UserDarkMode', null);
  const { darkMode = prefersDarkMode || userChoice } = useUserState();
  const [theme, setTheme] = React.useState(lightTheme);
  const { children } = props;

  useEffect(() => {
    setTheme(darkMode ? darkTheme : lightTheme);
  }, [darkMode]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        {children}
      </CssBaseline>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
