const { localStorage } = window;

export function setItem(key, value) {
  const setValue = JSON.stringify(value);
  localStorage.setItem(key, setValue);
  return true;
}

export function getItem(key) {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
}

export function removeItem(key) {
  localStorage.removeItem(key);
}
