import React from 'react';
import Lottie from 'react-lottie-player';

import animationData from '../../../Json/Animations/bounce-loader.json';

export function RocketLoader() {
  return (
    <div
      id="koodoo-rocketLoader"
      style={{
        width: '100vw',
        height: '100vh',
        display: 'grid',
        placeItems: 'center'
      }}
    >
      <Lottie
        loop
        animationData={animationData}
        play
        style={{ height: '60vh', maxWidth: '600px' }}
      />
    </div>
  );
}
