import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Raleway',
    h1: {
      fontFamily: 'Raleway'
    },
    h2: {
      fontFamily: 'Raleway'
    },
    h3: {
      fontFamily: 'Raleway'
    },
    h4: {
      fontFamily: 'Raleway'
    },
    h5: {
      fontFamily: 'Raleway'
    },
    h6: {
      fontFamily: 'DKMomotaro',
      fontWeight: '400',
      textAlign: 'center',
      backgroundColor: 'transparent',
      lineHeight: '1',
      fontSize: '6rem',
      color: 'white',
      margin: '0 0 24px',
      opacity: '1'
    }
  },
  palette: {
    background: { default: '#F4F4F4', alternate: '#EDEDED', dark: '#1C1F2A' },
    primary: { main: '#1C1F2A' },
    secondary: { main: '#00B7BD' },
    tertiary: { main: '#66bb6a' },
    quaterly: { main: '#00b3bd' },
    success: { main: '#00BB7E' },
    info: { main: '#B6ADA5' },
    error: { main: '#F65275' },
    type: 'light',
    text: {
      secondary: 'rgba(0, 0, 0, 0.87)',
      primary: 'rgba(0, 0, 0, 0.87)',
      inverted: 'rgba(255, 255, 255, 0.87)'
    }
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: 'rgba( 28, 31, 42, 0.90 ); !important',
        background: 'rgba( 28, 31, 42, 0.90 ); !important',
        boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
        backdropFilter: 'blur( 2px )',
        WebkitBackdropFilter: 'blur( 2px )',
        borderRadius: 0,
        border: 'none'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: 'rgba( 255, 255, 255, 0.90 ); !important',
        background: 'rgba( 255, 255, 255, 0.90 ); !important',
        boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
        backdropFilter: 'blur( 2px )',
        WebkitBackdropFilter: 'blur( 2px )',
        borderRadius: '10px',
        border: '1px solid rgba( 255, 255, 255, 0.18 )',
        color: 'black'
      }
    },
    MuiAppBar: {
      root: {
        border: 'none'
      }
    },
    MuiSlider: {
      rail: {
        color: '#00b3bd'
      },
      track: {
        color: '#00b3bd'
      },
      valueLabel: {
        color: '#F65275'
      }
    }
  },
  widget: {
    backgroundColor: 'rgba( 255, 255, 255, 0.60 ); !important',
    background: 'rgba( 255, 255, 255, 0.60 ); !important',
    boxShadow: '0 8px 16px 0 rgba( 31, 38, 135, 0.20 )',
    backdropFilter: 'blur( 2px )',
    WebkitBackdropFilter: 'blur( 2px )',
    borderRadius: '10px',
    color: 'black'
  },
  headerText: {
    fontFamily: 'DKMomotaro',
    fontWeight: '400',
    textAlign: 'center',
    backgroundColor: 'transparent',
    fontSize: '15vh',
    lineHeight: '0.939394em',
    color: 'white',
    margin: '0 0 24px',
    opacity: '1'
  }
});

export default theme;
